//const website = require('./website')

module.exports = {
  fr: {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    link: "lien",
    image: "image",
    modifiedThe: "dernière mise à jour le",
    at: "à",
    personal: "Personnel",
    "pro-bono": "Pro bono",
    with: "Avec",
    and: "et",
  },
  en: {
    default: false,
    label: "En",
    path: "en",
    locale: "en",
    siteLanguage: "en",
    ogLang: "en_GB",
    link: "link",
    image: "image",
    modifiedThe: "latest update on",
    at: "at",
    personal: "Personal",
    "pro-bono": "Pro bono",
    with: "With",
    and: "and",
  },
}
